.projects {
	&__logo {
		text-align: center;
		margin-bottom: 50px;
		@include respond-to('small') {
			margin-bottom: 30px;
		}
	}
	&__cats {}
	&__gallery {
		padding-top: 50px;
		@include respond-to('small') {
			padding-top: 30px;
		}
	}
}

.proj-categories {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@include respond-to('huge') {
		justify-content: center;
	}
	@include respond-to('medium') {
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;
		justify-content: start;
	}
	&__item {
		display: inline-block;
		padding: 0 5px;
		@include respond-to('huge') {
			padding: 0 15px;
		}
		@include respond-to('medium') {
			flex: 0 0 auto;
			padding: 0 10px;
		}
		& p {
			text-align: center;
			margin: 0;
			& a {
				display: inline-block;
				border-bottom: 2px solid transparent;
				padding: 5px 0;
				font-size: 16px;
				font-weight: 400;
				@include link_no-hover(#000);
			}
		}
		& .active {
			border-bottom: 2px solid #000;
		}
	}
}

.product-block {
	border-bottom: 1px solid #fff;
}

.product-item-wrap {
	float: right;
	width: 20%;
	padding: 1px;
	@include respond-to('large') {
		width: 33.33%;
	}
	@include respond-to('small') {
		width: 50%;
	}
	@include respond-to('mobile-m') {
		width: 100%;
	}
	&_long {
		width: 40%;
		@include respond-to('large') {
			width: 33.33%;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('mobile-m') {
			width: 100%;
		}
	}
}

.product-item {
	position: relative;
	display: block;
	overflow: hidden;
	height: 350px;
	width: 100%;
	text-align: center;
	background-position: center;
	background-size: cover;
	@include respond-to('huge') {
		height: 300px;
	}
	@include respond-to('larger') {
		height: 280px;
	}
	@include respond-to('medium') {
		// width: 50%;
		height: 250px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		height: 200px;
	}
	&_long {
		width: 40%;
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
	&:hover {
		.product-item__img {
			transform: scale3d(1.1, 1.1, 1.1);
		}
		.product-item__overlay {
			opacity: 1;
		}
	}
	&__img {
		display: block;
		max-width: 100%;
		height: 100%;
		width: 100%;
		transform: scale3d(1, 1, 1);
		transition: transform 0.15s ease 0s;
	}
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.8);
		opacity: 0;
		transition: opacity 0.15s ease 0s;
	}
	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& i {
			font-size: 40px;
			color: #fff;
		}
	}
	&__text {
		position: absolute;
		bottom: 35px;
		left: 50%;
		display: block;
		width: 70%;
		transform: translateX(-50%);
		text-align: center;
		color: #ffffff;
		font-size: 18px;
		font-weight: 300;
	}
}

.additional-info {
	&__plus {
		position: relative;
		width: 40px;
		height: 40px;
		margin: 40px auto 0;
		border: 1px solid #fff;
		@include respond-to('large') {
			margin-top: 20px;
		}
		&::after,
		&::before {
			content: '';
			display: block;
			background-color: #fff;
		}
		&::after {
			width: 23px;
			height: 1px;
			margin-right: 7px;
			margin-top: -12px;
		}
		&::before {
			width: 1px;
			height: 23px;
			margin-right: 18px;
			margin-top: 8px;
		}
		&_subcat {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
		}
	}
}