.product-box {
	display: block;
	position: relative;
	height: 540px;
	max-width: 352px;
	margin-right: auto;
	margin-left: auto;
	transition: box-shadow 0.2s ease;
	@include respond-to('large') {
		// max-width: none;
	}
	@include respond-to('small') {
		// margin-bottom: 20px;
		// max-width: 352px;
	}
	@include respond-to('extra-small') {
		height: 510px;
	}
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.07);
	}
	&__img {
		position: relative;
		overflow: hidden;
		height: 350px;
		margin: 0 auto;
		@include respond-to('extra-small') {
			height: 320px;
		}
		& img {
			width: 100%;
			height: 100%;
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
		}
	}
}

.product-box-info {
	height: 190px;
	padding-top: 18px;
	padding-bottom: 14px;
	text-align: center;
	border: 1px solid #f1f1f1;
	&__title {
		position: relative;
		overflow: hidden;
		max-height: 75px;
		padding-bottom: 8px;
		color: #848484;
		font-size: 25px;
		font-weight: 400;
		line-height: 1;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 1px;
			max-width: 195px;
			width: 100%;
			margin: 0 auto;
			background-color: #f8d5c1;
		}
		& p {
			margin: 0;
		}
	}
	&__price {
		padding-top: 8px;
	}
	&__price-desc {
		font-size: 18px;
		color: #848484;
		font-weight: 400;
		line-height: 1;
	}
	&__price-number {
		font-size: 25px;
		color: #848484;
		font-weight: 400;
		line-height: 1;
	}
	&__button {
		position: absolute;
		right: 0;
		bottom: 22px;
		left: 0;
		margin: 0 auto;
		width: 260px;
		height: 40px;
		padding: 9px;
		background-color: #f8d5c1;
		color: #fff;
		text-transform: uppercase;
		font-size: 18px;
	}
}

.btn-with-cart {
	width: 153px;
	height: 40px;
	background-color: #f8d5c1;
	&:active,
	&:focus {
		@include btn-effect-active;
	}
}

.prod-box-text-wrap {
	padding: 15px 13px;
	background-color: #f8d5c1;
	@include respond-to('huge') {
		padding: 10px;
	}
}
.prod-box-text {
	overflow: hidden;
	height: 100%;
	border: 1px solid #fff;
	padding: 15px 7px;
	@include respond-to('huge') {
		padding: 10px 5px;
	}
	& p {
		direction: ltr;
		margin: 0;
		color: #fff;
		line-height: 1.4;
		@include respond-to('huge') {
			font-size: 16px;
		}
	}
}