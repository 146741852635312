footer {
	width: 100%;
	position: relative;
	background-color: #000;
	z-index: 1;
	padding: 40px 0 30px;
	@include respond-to('medium') {
		padding: 25px 0 20px;
	}
}

.credit-info-txt {
	display: inline-block;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-right: 0;
	}
	& > p {
		font-size: 18px;
		color: #fff;
		font-weight: 400;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.wrap-social {
	position: relative;
	display: inline-block;
	margin-#{$right}: 0;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin: 0;
		margin-bottom: 10px;
	}
	& > a {
		& > i {
			font-size: 14px;
			color: #fff;
			vertical-align: top;
		}
		&:hover {
			& > .fa-twitter {
				color: $clr-twitter;
			}
			& > .fa-youtube {
				color: $clr-youtube;
			}
			& > .fa-facebook-f {
				color: $clr-facebook;
			}
		}
	}
}

.site-footer__credit-holder {
	float: left;
	@include respond-to('medium') {
		float: none;
	}
}