.item-info {
	& .row_no-gutter {
		@include respond-to('large') {
			& [class^='col-'] {
				padding: 0 15px;
			}
		}
	}
	& .section-limit {
		@include respond-to('large') {
			padding: 0;
		}
	}
}

.product-description-wrap {
	padding-top: 25px;
	@include respond-to('small') {
		padding-top: 20px;
	}
}

.product-item-description {
	float: left;
	max-width: 577px;
	width: 100%;
	color: #848484;
	font-weight: 400;
	@include respond-to('medium') {
		float: none;
		padding-top: 30px;
		max-width: 700px;
		width: 100%;
		margin: 0 auto;
	}
	&__title {
		font-size: 0;
		& span {
			position: relative;
			display: inline-block;
			padding-right: 15px;
			font-size: 40px;
			@include respond-to('small') {
				font-size: 35px;
			}
			@include respond-to('extra-small') {
				font-size: 25px;
			}
			&:first-child {
				padding-left: 15px;
				padding-right: 0;
				&::after {
					content: '';
					position: absolute;
					left: -1px;
					top: 0;
					width: 1px;
					height: 80%;
					background-color: #f8d5c1;
				}
			}
		}
	}
	&__price {
		font-size: 30px;
		padding-bottom: 10px;
		margin-bottom: 50px;
		border-bottom: 1px solid #f8d5c1;
		@include respond-to('small') {
			margin-bottom: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 25px;
		}
	}
	&__text {
		font-size: 20px;
		@include respond-to('extra-small') {
			font-size: 18px;
		}
		& p {
			margin-bottom: 25px;
			text-align: left;
			direction: ltr;
			&:first-child {
				direction: rtl;
				text-align: right;
			}
		}
	}
	&__quantity {
		float: left;
		direction: ltr;
		margin-bottom: 40px;
		@include respond-to('small') {
			margin-bottom: 25px;
		}
		& label {
			display: block;
			font-size: 20px;
			font-weight: 400;
			@include respond-to('extra-small') {
				font-size: 18px;
			}
		}
		& select {
			width: 100%;
			height: 100%;
			border: 1px solid #ededed;
			padding: 0 15px;
			appearance: none;
		}
	}
	&__checkbox {
		clear: both;
		border-top: 1px solid #f8d5c1;
		padding-top: 25px;
		direction: ltr;
	}
	&__btn {
		padding-top: 40px;
		text-align: center;
		@include respond-to('small') {
			padding-top: 25px;
		}
		& .product-box-info__button {
			display: block;
			position: static;
			left: auto;
			top: auto;
			right: auto;
			width: 100%;
			height: 60px;
			padding: 14px;
			font-size: 25px;
      font-weight: 400;
      border: none;
			@include link_no-hover(#fff);
			&:active {
				box-shadow: inset 0 5px 10px 0px rgba($color: #000000, $alpha: 0.1);
			}
		}
	}
}

.select-wrap {
	position: relative;
	width: 100px;
	height: 40px;
	&::after {
		pointer-events: none;
		content: url(../images/select-arrow.png);
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.prod-gallery {
	position: relative;
	float: $left;
	width: 71%;
	max-width: 612px;
	padding-left: 112px;
	@include respond-to('huge') {
		padding-left: 50px;
	}
	@include respond-to('large') {
		padding-left: 0;
		float: none;
		width: 100%;
		max-width: none;
	}
	@include respond-to('medium') {
		max-width: 470px;
		margin: 0 auto;
	}
	@include respond-to('extra-small') {
		max-width: 350px;
	}
	@include respond-to('mobile-m') {
		max-width: 280px;
	}
	.slide {
		display: none;
	}
	.slide.slick-slide:first-child {
		display: block;
	}
	.slide.slick-slide {
		display: block;
	}
	&_padding {
		padding: 0;
		cursor: pointer;
	}
	&__img-big {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 600px;
		@include respond-to('medium') {
			height: 540px;
		}
		@include respond-to('extra-small') {
			height: 400px;
		}
		@include respond-to('mobile-m') {
			height: 320px;
		}
		&:hover {
			cursor: zoom-in;
		}
		img {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			width: 100%;
			height: 100%;
			margin: 0 auto;
		}
	}
}

.prod-gallery-navi {
	position: relative;
	float: $left;
	width: 29%;
	max-width: 234px;
	height: 600px;
	text-align: center;
	margin-top: -15px;
	&:hover {
		cursor: grab;
	}
	@include respond-to('large') {
		float: none;
		width: 100%;
		max-width: 500px;
		height: auto;
		margin: 0 auto;
	}
	.slide {
		display: none;
	}
	&.slick-vertical .slick-slide {
		// border: none !important;
	}
	.slick-list {
		padding: 15px 0 0 34px;
		max-height: 615px;
		@include respond-to('large') {
			padding: 15px 0 0 0;
		}
		@include respond-to('extra-small') {
			padding: 10px 0;
		}
	}
	.slide.slick-slide:first-child {
		display: block;
	}
	.slide.slick-slide {
		display: block;
	}
	&__item {
		border: 1px solid #ededed !important;
		position: relative;
		min-height: 180px;
		max-height: 180px;
		overflow: hidden;
		margin-bottom: 30px;
		@include respond-to('large') {
			margin: 10px 15px;
			min-height: 140px;
			max-height: 140px;
		}
		@include respond-to('extra-small') {
			min-height: 100px;
			margin: 0 5px;
		}
		@include respond-to('mobile-m') {
			// min-height: 100px;
		}
		& img {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}
	}
	&__item.slick-current {
		// box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
	}
}

.slick-current {
	.gallery-img-small-shadow {
		background-color: rgba($color: #ffffff, $alpha: 0);
	}
}

.custom-check-box-wrap {
	margin-bottom: 4px;
}

.custom-check-box {
	position: relative;
	width: 30px;
	height: 20px;
	display: inline-block;
	margin-#{$right}: 10px;
	label {
		width: 30px;
		height: 20px;
		cursor: pointer;
		position: absolute;
		background-color: #fff;
		border: 1px solid #ededed;
		#{$left}: 0;
		top: 0;
		&::after {
			content: url(../images/check-label.png);
			position: absolute;
			top: 0px;
			#{$left}: 8px;
			opacity: 0;
		}
	}
	input[type=radio] {
		visibility: hidden;
		&:checked + label:after {
			opacity: 1;
		}
	}
}

.custom-check-box-text {
	display: inline-block;
	& > p {
		font-size: 20px;
		font-weight: 400;
		color: #848484;
		margin: 0;
		@include respond-to('small') {
			font-size: 16px;
		}
	}
}