@font-face {
	font-family: 'Miriam Fixed';
	src: url(fonts/custom/MiriamFixed.woff2) format('woff2'),
		url(fonts/custom/MiriamFixed.woff) format('woff'),
		url(fonts/custom/MiriamFixed.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Miriam Fixed';
	src: url(fonts/custom/MiriamFixed_1.woff2) format('woff2'),
		url(fonts/custom/MiriamFixed_1.woff) format('woff'),
		url(fonts/custom/MiriamFixed_1.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
}