.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
}

.header-desktop {
	height: 70px;
	@include respond-to('medium') {
		display: none;
	}
}

.navi {
	display: block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: 25px 0 0 0;
		padding: 0;
		& > li {
			position: relative;
			& > a {
				display: block;
				border-bottom: 1px solid transparent;
				padding: 0 0 5px 0;
				color: #000;
				font-size: 16px;
				font-weight: 600;
				&:hover {
					border-bottom: 1px solid #000;
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					border-bottom: 1px solid #000;
				}
			}
		}
	}
	&_white {
		& ul li a {
			color: #fff;
			&:hover {
				border-bottom: 1px solid #fff;
			}
			&.active {
				border-bottom: 1px solid #fff;
			}
		}
	}
	&__hebrew {
		float: right;
		width: 50%;
		& li {
			float: right;
			margin-right: 30px;
		}
	}
	&__eng {
		float: left;
		width: 50%;
		text-align: left;
		& li {
			float: left;
			margin-left: 30px;
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 10px;
		float: $left;
		& > img {
			width: 200px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: #000;
			font-size: 28px;
		}
		&_white {
			i {
				color: #fff;
			}
		}
	}
}

.hamburger-box {
  width: 32px;
	&_white {
		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
      background-color: #fff;
      width: 32px;
      height: 3px;
		}
	}
}

.main-header.scrolling {
	background-color: rgba($color: #fff, $alpha: 0.9);
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
	.header-desktop {
	}
	.header-mobile {

	}
}