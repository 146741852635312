@charset 'UTF-8';

$dir: rtl !default;

@import
  'abstracts/functions',
  'abstracts/variables',
  'abstracts/variables-base64',
  'abstracts/mixins';

@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/blocks_shared/accessibility/accessibility.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/blocks_shared/side-menu/side-menu.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/blocks_shared/site-footer/site-footer-credit/site-footer-credit.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/blocks_shared/site-footer/site-footer.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/blocks_shared/site-header/site-header.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/layout/base-layout/base-layout.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/layout/page-layout/page-layout.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/layout/page-with-side-layout/page-with-side-layout.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/catalog/catalog-item/catalog-item.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/catalog/catalog-page.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/catalog/product-box/product-box.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/contact-us/form-elements.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/contact-us/form.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/content/content-page.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/homepage/homepage-slider/homepage-slider.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/homepage/homepage.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/orders/cart.scss";
@import "D:/xampp/htdocs/_production/_php/R/revital-indik.co.il#php/app/resources/pages/projects/projects-page.scss";
