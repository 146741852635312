.cat-block {
	margin: 0 -10px;
}

.prod-box-wrap {
	padding-top: 10px;
	& .row {
		margin: 0;
		& [class^='col-'] {
			padding: 0;
		}
	}
}

.prod-item-wrap {
	padding: 10px;
}