.wrap-content {
	padding: 120px 0 80px;
	@include respond-to('small') {
		padding: 110px 0 40px;
  }
  @include respond-to('extra-small') {
		padding: 70px 0 40px;
	}
	&_pb40 {
		padding-bottom: 40px;
	}
}

.wrap-all-content-not-banner {
  margin-top: 120px;
  @include respond-to('medium') {
		margin-top: 80px;
	}
}

.title {
	float: left;
	max-width: 577px;
	width: 100%;
	font-size: 40px;
	font-weight: 300;
	line-height: 1;
	color: #165683;
  margin: 0 0 40px;
  & img{
    @include respond-to('extra-small') {
      max-width: 70%;
    }
  }
	@include respond-to('larger') {
		padding: 0 15px;
	}
	@include respond-to('medium') {
		max-width: none;
		float: none;
		text-align: center;
	}
}

.wrap-all-content-not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-img {
	text-align: center;
	&__photo {
		box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
	}
	&_padd {
		@include respond-to('larger') {
			padding-left: 15px;
		}
		@include respond-to('medium') {
			padding-left: 0;
		}
	}
}

.content-text {
	float: left;
	max-width: 577px;
	width: 100%;
	@include respond-to('larger') {
		padding: 0 15px 0 0;
	}
	@include respond-to('medium') {
		max-width: 700px;
		margin: 0 auto;
		float: none;
		padding: 0;
	}
	p {
		font-size: 25px;
		font-weight: 400;
		color: #000;
		line-height: 1.1;
		text-align: justify;
		@include respond-to('large') {
			font-size: 20px;
		}
		@include respond-to('small') {
			font-size: 18px;
		}
		& span {
			font-size: 35px;
			@include respond-to('large') {
				font-size: 30px;
			}
			@include respond-to('small') {
				font-size: 22px;
			}
		}

	}
	&__heb {
		margin-bottom: 55px;
		@include respond-to('large') {
			margin-bottom: 25px;
		}
		& p {
			margin: 0;
		}
	}
	&__eng {
		direction: ltr;
		@include respond-to('medium') {
			margin-bottom: 20px;
		}
		& p {
			margin: 0;
			& span {
				font-size: 40px;
				@include respond-to('large') {
					font-size: 35px;
				}
				@include respond-to('small') {
					font-size: 25px;
				}
			}
			& span.wide-letter {
				letter-spacing: 36px;
				@include respond-to('medium') {
					letter-spacing: normal;
				}
			}
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}