.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.3);
	}
	&__logo {
		position: absolute;
		z-index: 1;
		transform: translateX(-50%);
		@include respond-to('extra-small') {
      top: 60px !important;
      left: 50% !important;
		}
		& img {
			@include respond-to('larger') {
				max-width: 450px;
			}
			@include respond-to('small') {
				max-width: 350px;
			}
			@include respond-to('extra-small') {
				max-width: 300px;
			}
		}

	}
	&__desc {
		position: absolute;
		z-index: 1;
		direction: ltr;
		@include respond-to('medium') {
			bottom: 20px !important;
			right: 20px !important;
		}
		@include respond-to('extra-small') {
			padding-left: 15px;
		}
		& p {
			font-family: 'EB Garamond', serif;
			text-align: left;
			font-size: 40px;
			letter-spacing: 1px;
			font-weight: 500;
			color: #fff;
			line-height: 1.2;
			@include respond-to('small') {
				font-size: 35px;
			}
			@include respond-to('extra-small') {
				font-size: 30px;
			}
		}
	}
}

.desc-fz-45 {
	font-family: 'Noto Sans JP', sans-serif;
	font-size: 40px;
	font-weight: 400;
	letter-spacing: 5px;
	@include respond-to('small') {
		font-size: 35px;
	}
	@include respond-to('extra-small') {
		font-size: 30px;
	}
}

.desc-fz-65 {
	text-transform: uppercase;
	font-size: 50px;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 3px;
	font-family: 'Yanone Kaffeesatz', sans-serif;
	@include respond-to('small') {
		font-size: 40px;
	}
	@include respond-to('extra-small') {
		font-size: 35px;
	}
}