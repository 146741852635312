.contacts-block {
	clear: both;
	float: left;
	max-width: 577px;
	width: 100%;
	padding-top: 60px;
	@include respond-to('larger') {
		padding-top: 30px;
	}
	@include respond-to('large') {
		padding-top: 0;
	}
	@include respond-to('medium') {
		float: none;
		max-width: 700px;
		margin: 0 auto;
		padding: 0 0 15px 0;
	}
	&__row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 10px;
		@include respond-to('small') {
			margin: 20px;
		}
	}
	&__item {
		text-align: center;
		font-size: 20px;
		@include respond-to('large') {
			flex: 1 1 100%;
		}
		@include respond-to('medium') {
			flex: none;
		}
		@include respond-to('small') {
			flex: 1 1 100%;
		}
	}
}

.hebrew {}
.english {
	direction: ltr;
}

.soc-block {
	margin-top: 100px;
	@include respond-to('larger') {
		margin-top: 50px;
	}
	& a {
		margin: 0 5px;
	}
}